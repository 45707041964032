import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";

import SEO from '../components/SEO';
import ContactWidget from '../components/ContactWidget';
import ModalsFlow from '../components/Modals/ModalsFlow';

import iconFilter from '../assets/images/icons/other/Filter Icon@3x.svg';
import loadable from '@loadable/component';

const ReactMarkdown = loadable(() => import("react-markdown"))

const BlogPostTemplate = ({ data, location }) => {
  const postData = data.markdownRemark;
  const postsData = data.allMarkdownRemark.edges;

  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  return <>
    <SEO
      title={
        postData.frontmatter.seo && postData.frontmatter.seo.seo_title
          ? postData.frontmatter.seo.seo_title
          : postData.frontmatter.title
      }
      description={
        postData.frontmatter.seo && postData.frontmatter.seo.seo_description
          ? postData.frontmatter.seo.seo_description
          : postData.frontmatter.description
      }
      image={postData.frontmatter.thumbnail.publicURL}
      postSEO={{
        authorAvatar: postData.frontmatter.avatar.publicURL,
        authorName: postData.frontmatter.author,
        datePublished: postData.frontmatter.date,
      }}
    />
    <div className="common-page blog-template">
      <div className="blog-template__category">
        <div className="blog-template__category__wrapper">
          <img src={iconFilter} alt="category" />
          <span>{postData.frontmatter.category}</span>
        </div>
      </div>

      <div className="container">
        <div className="blog-template__wrapper">
          <div className="blog-template__content">
            <div className="blog-template__content__body">
              <h1 className="blog-template__content__body__title">
                {postData.frontmatter.title}
              </h1>
              <ReactMarkdown children={postData.rawMarkdownBody} />
            </div>
            <div className="blog-template__content__author">
              <GatsbyImage
                image={postData.frontmatter.avatar.childImageSharp.gatsbyImageData}
                alt={`Author - ${postData.frontmatter.author}`}
                title={`${postData.frontmatter.author}`} />
              <div className="content">
                <span className="name">{postData.frontmatter.author}</span>
                <span className="date">{postData.frontmatter.date}</span>
              </div>
            </div>
          </div>
          
        </div>

        <div className="blog-template__related">
          <h4 className="paragraph">Related blogs</h4>
          <div className="row">
            {postsData.map((post) => (
              <div className="col-md-4" key={post.node.id}>
                <Link to={post.node.fields.slug} className="blog-card">
                  <div className="blog-card__content">
                    <h4 className="title">{post.node.frontmatter.title}</h4>
                    <p className="caption">{post.node.frontmatter.description}…</p>
                    <div className="blog-card__content__bottom">
                      <span className="date">{post.node.frontmatter.date}</span>
                      <span className="category">{post.node.frontmatter.category}</span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        <ContactWidget
          handleClick={() => setIsContactModalOpen(true)}
          title="Speak to a mortgage expert for FREE"
        />
        <ModalsFlow
          isFirstOpened={isContactModalOpen}
          setIsFirstOpened={(state) => setIsContactModalOpen(state)}
          firstModalIndex={1}
          usePureCalendlyWidget
        />
      </div>
    </div>
  </>;
};

export default BlogPostTemplate;

export const pageQuery = graphql`query BlogPost($id: String!) {
  markdownRemark(id: {eq: $id}) {
    id
    rawMarkdownBody
    frontmatter {
      title
      description
      category
      date
      author
      thumbnail {
        publicURL
      }
      avatar {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 46, height: 46, layout: FIXED)
        }
      }
      seo {
        seo_title
        seo_description
      }
    }
  }
  allMarkdownRemark(
    limit: 3
    sort: {fields: frontmatter___date, order: DESC}
    filter: {id: {ne: $id}, frontmatter: {templateKey: {eq: "blog-item"}}}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          title
          description
          date
          category
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 370, height: 200, quality: 100, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}`;
